<template>
  <div class="scrollable only-y register-page">
    <div v-if="!showDonwloadTable" class="form-wrapper align-vertical-middle" v-loading="loading_table"
      :element-loading-text="text_loading" element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <img class="image-logo" :src="pathImagesUrl + '/banner.png'" alt="logo" />
      <div class="no-play" v-if="verify_data && !loading_verify">
        Bingo!!
        <br />
        <div class="return-play">
          Tu registro ha sido verificado correctamente.
        </div>
        <div class="info-pay">
          Por favor realiza el pago de tus tablas para que puedas descargarlas y comenzar a jugar!
        </div>
        <el-divider class="divider"></el-divider>
        <div class="invoice-table-content">
          <table class="invoice-table">
            <tr>
              <td class="info">Valor tabla</td>
              <td class="value">$ {{ formatN(category.ammount) }}</td>
            </tr>
            <tr>
              <td class="info">IVA</td>
              <td class="value">$ {{ formatN(category.value_tax) }}</td>
            </tr>
            <tr>
              <td class="info">Subsidio</td>
              <td class="value">$ {{ formatN(category.value_subsidy) }}</td>
            </tr>
            <tr>
              <th class="total">Total a pagar</th>
              <th class="total-value">$ {{ formatN(category.ammount - category.value_subsidy) }}</th>
            </tr>
          </table>
        </div>
        <div class="tear-off"></div>

        <button-wompi :dataplayer="dataPlayer" :datacheckout="dataCheckout" :invoice="invoice" :loading="loadingPayConfirmation"
          @assign-table="assignTablePlayer" @show-download="showDownload" @stop-loading="stopLoadingWompi"></button-wompi>

        <!-- <button-wompi :activebtn="activeCheckout" :datacheckout="dataCheckout" @transaction="verifyTransaction"
          @show-error="showError" @show-pay="showPay" @show-download="showDownload"
          @invoice="getDataInvoice"></button-wompi> -->

        <button-epayco :dataplayer="dataPlayer" :datacheckout="dataCheckout" :invoice="invoice" :loading="loadingPayConfirmation"
        @assign-table="assignTablePlayer" @show-download="showDownload" @stop-loading="stopLoadingEpayco"></button-epayco>
      </div>
      <div class="no-play" v-if="!verify_data && !loading_verify">
        No pudimos verificar tu registro :(
        <br />
        <span class="return-play">{{ msn }}</span>
      </div>
      <div v-if="loading_verify" class="loading-game">
        <i class="el-icon-loading"></i> Validando registro...
        <!-- <button-wompi v-show="false" :activebtn="activeCheckout" :dataplay="dataCheckout" @transaction="verifyTransaction"
          @show-error="showError" @show-pay="showPay" @show-download="showDownload"
          @invoice="getDataInvoice"></button-wompi> -->
      </div>
      <button-return-register></button-return-register>
    </div>
    <download-table v-else :tables="tablesBingo" :invoice="{ invoice, category }"></download-table>
    <!-- <el-button @click="assignTablePlayer">Asignar tabla</el-button> -->
  </div>
</template>
<script>
import ButtonReturnRegister from "@/views/apps/modules/ButtonReturnRegister.vue";
import ButtonWompi from "../checkout/ButtonWompi.vue";
import ButtonEpayco from "../checkout/ButtonEpayco.vue";
import DownloadTable from "./DownloadTable.vue";
import { formatNumber } from "@/utils/number";
import { getInvoices, assignTable, downloadTable } from "@/api/player";
export default {
  name: "PaymentTable",
  components: {
    ButtonReturnRegister,
    ButtonWompi,
    ButtonEpayco,
    DownloadTable,
  },
  data() {
    return {
      pathImagesUrl: process.env.VUE_APP_ROOT + 'images/' + process.env.VUE_APP_FOLDER_IMAGES,
      showDonwloadTable: false,
      text_loading: "",
      loading_download: false,
      loading_table: false,
      loading_verify: true,
      verify_data: false,
      loadingPayConfirmation: false,
      table: { table_bingo: "" },
      msn: "",
      activeCheckout: false,
      dataCheckout: null,
      dataPlayer: null,
      dataPlayerUrl: null,
      tablesBingo: null,
      invoice: null,
      category: null,
    };
  },
  created() {
    var params = this.$route.params;
    this.activeCheckout = true
    this.dataPlayerUrl = {
      playerId: params.id, playerVerificationCode: params.code
    }
  },
  mounted() {
    this.getDataCheckout()
  },
  methods: {
    async getDataCheckout() {
      let me = this
      this.loading_verify = true
      await getInvoices(this.dataPlayerUrl.playerId, this.dataPlayerUrl.playerVerificationCode)
        .then(({ data }) => {
          if (data.code === 200) {
            me.getDataInvoice(data.data.invoice)
            if (data.data.confirmed === 1 && data.data.pay_table === 1) {
              me.invoice = data.data.invoice
              me.showDownload()
            } else {
              if (data.data.invoice.transaction_status === 'PENDING'
                && data.data.invoice.transaction_id !== ''
                && data.data.invoice.transaction_id !== null) {
                me.dataCheckout = data.dataCheckout
                me.dataPlayer = data.data
                me.loading_verify = false
                me.verify_data = true
                setTimeout(() => {
                  me.loadingPayConfirmation = true
                }, 100);
                setTimeout(() => {
                  me.invoice = data.data.invoice
                }, 5000);
              } else {
                me.dataCheckout = data.dataCheckout
                me.dataPlayer = data.data
                me.verify_data = true
                me.loading_verify = false
              }
            }
          } else {
            me.loading_verify = false
            me.showDonwloadTable = false
          }
        })
        .catch(function (error) {
          // this.loadingPayment = false
          console.log(error);
          me.loading_table = false;
          if (error.response) {
            // El servidor respondió con un código de estado fuera del rango 2xx
            me.$message.error("Error: " + error.response.data.message);
            console.error('Error de respuesta:', error.response.data);
            console.error('Código de estado HTTP:', error.response.status);
          } else if (error.request) {
            me.$message.error("Error: No se recibió respuesta del servidor");
            // La solicitud fue realizada pero no se recibió una respuesta
            console.error('No se recibió respuesta del servidor');
          } else {
            // Ocurrió un error durante la configuración de la solicitud
            me.$message.error('Error al configurar la solicitud: ' + error.message);
            console.error('Error al configurar la solicitud:', error.message);
          }
        });
    },
    getDataInvoice(invoice) {
      // console.log('invo: ', invoice);
      // this.invoice = invoice
      this.category = JSON.parse(invoice.categorie)
      let tax = this.category.ammount * this.category.tax / 100
      let subsidy = this.category.ammount * this.category.subsidy / 100
      this.category.value_tax = tax
      this.category.value_subsidy = subsidy
    },
    downloadTable() {
      this.loading_download = true;
      downloadTable(this.table)
        .then(({ data }) => {
          this.downloadFile(data, "Mis tablas de bingo");
        })
        .catch(function (error) {
          this.loading_download = false;
          this.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      this.loading_download = false;
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    assignTablePlayer() {
      let me = this
      me.loading_table = true;
      assignTable(this.dataPlayerUrl)
        .then(({ data }) => {
          me.loading_table = false;
          me.showDonwloadTable = true;
          me.tablesBingo = data.table
          console.log('asingTable: ', data);
        })
        .catch(function (error) {
          console.log(error);
          me.loading_table = false;
          if (error.response) {
            // El servidor respondió con un código de estado fuera del rango 2xx
            me.$message.error("Error: " + error.response.data.message);
            console.error('Error de respuesta:', error.response.data);
            console.error('Código de estado HTTP:', error.response.status);
          } else if (error.request) {
            me.$message.error("Error: No se recibió respuesta del servidor");
            // La solicitud fue realizada pero no se recibió una respuesta
            console.error('No se recibió respuesta del servidor');
          } else {
            // Ocurrió un error durante la configuración de la solicitud
            me.$message.error('Error al configurar la solicitud: ' + error.message);
            console.error('Error al configurar la solicitud:', error.message);
          }
        });
    },
    stopLoadingWompi(data){
      this.loadingPayConfirmation = data
    },
    stopLoadingEpayco(data){
      this.loadingPayConfirmation = data
    },
    showPay(data) {
      let me = this
      me.loading_verify = false
      me.verify_data = true
    },
    showError(data) {
      let me = this
      me.loading_verify = false
      me.showDonwloadTable = false
    },
    showDownload(data) {
      this.showDonwloadTable = true
      this.assignTablePlayer()
    },
    formatN(number) {
      return formatNumber(number)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/_variables";

.invoice-table-content {
  text-align: -webkit-center;
  background-color: white;
  position: relative;
  padding: 15px;
  border-radius: 20px;

  .invoice-table {
    font-size: 15px;
    color: #676767;
    font-weight: 600;

    .info,
    .total {
      text-align: left;
      width: 200px;
    }

    .value,
    .total-value {
      text-align: right;
    }

    .total,
    .total-value {
      border-top: 1px solid;
      font-size: 19px;
    }
  }


}


.divider {
  background-color: #5f8fdf;
}

.info-pay {
  font-size: 17px;
}

.register-page {
  .btn-download-table {
    font-size: 20px;
  }

  .return-play {
    font-size: 25px;
    color: #0868AE;
    font-weight: 900;
  }

  .no-play {
    text-align: center;
    color: #da5635;
    border: 2px solid #DBDBEA;
    border-radius: 30px;
    font-size: 40px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 40px;
    margin-top: 50px;
    width: 400px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }

  .loading-game {
    color: #0868AE;
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  // .return-play {
  //   font-size: 25px;
  //   color: #ffee78;
  //   font-weight: 900;
  // }
  // .return-play:hover {
  //   color: #ffdf00;
  // }


  .accept {
    margin-top: 20px;
  }

  .form-wrapper {
    width: 100%;
    text-align: center;
  }

  .image-logo {
    width: 300px;
    margin: 0px auto;
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.register-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }

  .register-page {
    .image-logo {
      margin-top: 0px;
    }
  }
}

@media (max-width: 550px) {
  .register-page {
    .no-play {
      width: auto;
    }
  }
}

@media (max-width: 390px) {
  .register-page {
    .no-play {
      font-size: 22px;

      .return-play {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 330px) {
  .register-page {
    .image-logo {
      width: 200px;
    }

    .btn-download-table {
      font-size: 14px;
    }
  }
}
</style>
