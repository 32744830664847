<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> <i class="el-icon-s-grid"></i> Lista de Juegos </span>
        <!-- <el-button
          style="float: right"
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="createSerial"
          >Nuevo Juego</el-button
        > -->
        <el-button
          style="float: right"
          size="mini"
          icon="el-icon-refresh"
          @click="getData"
          >Actualizar Tabla</el-button
        >
        <el-switch
          style="float: right; margin-right: 50px"
          v-model="maintenance"
          @change="setMaintenanceSerial"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="Juegos activos"
          inactive-text="En mantenimiento"
        >
        </el-switch>
      </div>
      <el-table
        :data="
          tableData.filter(
            (data) =>
              !search ||
              data.serial.toLowerCase().includes(search.toLowerCase()) ||
              data.start_date.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="loading"
        height="500"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>Icono: {{ props.row.channel_icon }}</p>
            <p># jugados: {{ props.row.json_number_played }}</p>
            <p>Obsercacón: {{ props.row.observation }}</p>
            <p>Terminos: {{ props.row.terms }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="serial"
          label="Serial"
          width="150"
        ></el-table-column>
        <el-table-column prop="status" label="Estado" width="100">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status === 'open'
                  ? 'primary'
                  : scope.row.status === 'close'
                  ? 'danger'
                  : 'warning'
              "
              disable-transitions
              >{{
                scope.row.status === "open"
                  ? "Abierto"
                  : scope.row.status === "close"
                  ? "Cerrado"
                  : "Mantenimiento"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="start_date" label="Fecha" width="150">
          <template slot-scope="scope">
            <div>Fecha: {{ scope.row.start_date }}</div>
            <div>Hora: {{ scope.row.start_time }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="variable_prize"
          label="Total Registrados"
          width="270"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.players_game_date"
              :key="item.game_date"
            >
              <i class="el-icon-date"></i>
              {{ item.game_date + ": " }}
              <span class="total_players">{{ formatNumber(item.total) }}</span>
            </div>
            <div>
              <i class="el-icon-user"></i> Total Tablas: 
              <span class="total_players">{{ formatNumber(scope.row.total_tables) }}</span>
              &nbsp;
              <i class="el-icon-user"></i> Total:
              <span class="total_players">{{
                formatNumber(scope.row.players)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="url_transmission"
          label="Transmisión"
        ></el-table-column> -->
        <el-table-column align="right">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="Buscar" />
          </template>
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="Resetear Juego"
              placement="top"
              v-if="user.email == 'apps@aplicacionesexcel.com'"
            >
              <el-button
                size="mini"
                type="info"
                icon="el-icon-refresh"
                @click="resetSerial(scope.$index, scope.row)"
                >Resetear</el-button
              >
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Editar Juego"
              placement="top"
            >
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-edit"
                @click="editSerial(scope.$index, scope.row)"
                >Editar</el-button
              >
            </el-tooltip>
            <el-dropdown @command="handleCommand">
              <el-button
                type="primary"
                size="mini"
                circle
                style="margin-left: 10px"
              >
                <i class="el-icon-more-outline"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-download"
                  :command="{
                    data: scope.row,
                    type: 'downloadPlayers',
                  }"
                  >Descargar Jugadores</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-s-grid"
                  v-if="user.name === 'Administrador'"
                  :command="{
                    data: scope.row,
                    type: 'listTables',
                  }"
                  >Listar Tablas</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-setting"
                  :command="{
                    data: scope.row,
                    type: 'settingTables',
                  }"
                  >Campos en tablas</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-circle-plus-outline"
                  :command="{
                    data: scope.row,
                    type: 'createPlayers',
                  }"
                  >Crear jugadores</el-dropdown-item
                >
                <el-dropdown-item
                  divided
                  :command="{
                    data: scope.row,
                    type: 'delete',
                  }"
                >
                  <span style="color: #f56c6c" v-if="scope.row.bingo"
                    ><i class="el-icon-delete"></i> Eliminar Serial</span
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <modal-tables
      :modalOpen="openModalTables"
      :serial_id="serial_id"
      @close="openModalTables = false"
    ></modal-tables>
    <modal-serial
      :modalOpenSerial="openModalSerial"
      :edit="editSerials"
      :serial_id="serial_id"
      @close="closeSerial"
      @refresh="getData()"
    ></modal-serial>
    <modal-settings
      :modalOpenSerial="openModalSettings"
      :serial_id="serial_id"
      @close-settings="closeSettings"
    ></modal-settings>
    <modal-create-players
      :modalOpenCreatePlayers="openModalCreatePlayers"
      :serial_id="serial_id"
      @close-create-players="closeCreatePlayers"
      @refresh="getData()"
    ></modal-create-players>
    <el-dialog
      :visible.sync="openModalDownload"
      :before-close="closeModalDownload"
      width="20%"
      center
    >
      <div slot="title">
        <i class="el-icon-download"></i> Descarga el archivo
      </div>
      <div style="text-align: center">
        <i class="mdi mdi-file-excel" style="font-size: 50px"></i>
        <download-excel
          class="btn btn-default"
          :data="json_data"
          :fields="json_fields"
          :meta = "json_meta"
          :title="[]"
          type="csv"
          :name="'Jugadores.xls'"
          :before-finish="finishDownload"
        >
          <el-button size="mini" type="success" icon="el-icon-download"
            >Descargar datos </el-button
          >
        </download-excel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utf8 from "utf8";
import ModalSerial from "../../../views/apps/modules/ModalSerial.vue";
import ModalTables from "../../../views/apps/modules/ModalTables.vue";
import ModalSettings from "../../../views/apps/modules/ModalSettings.vue";
import ModalCreatePlayers from "../../../views/apps/modules/ModalCreatePlayers.vue";
import {
  getSerials,
  exportPlayer,
  deleteSerial,
  setMaintenance,
  resetSerial,
} from "@/api/serial";
import { formatNumber } from "@/utils/number";
import { getUser } from "@/utils/auth";
export default {
  name: "Serial",
  components: {
    ModalSerial,
    ModalTables,
    ModalSettings,
    ModalCreatePlayers,
  },
  props: ["show"],
  watch: {
    show: function (val) {
      if (val) {
        this.getData();
      }
    },
  },
  data() {
    return {
      user: getUser(),
      tableData: [],
      loading: false,
      loadingBtn: false,
      search: "",
      serial_id: "",
      openModalTables: false,
      openModalSerial: false,
      editSerials: false,
      openModalDownload: false,
      openModalSettings: false,
      openModalCreatePlayers: false,
      json_fields: {
        Nombre: "name_player",
        Cedula: "nit",
        Telefono: "phone",
        Ciudad: "city",
        // Pais: {
        //   field: "meta_data",
        //   callback: (value) => {
        //     var country = "";
        //     if (value) {
        //       // var jsonData = JSON.parse(value);
        //       if (value.country) {
        //         country = value.country;
        //       }
        //     }
        //     return `${country}`;
        //   },
        // },
        Tabla: {
          field: "table_bingo",
          callback: (value) => {
            return `${value}`;
          },
        },
        tabla_id: {
          field: "tables_bingo_id",
          callback: (value) => {
            return `${value}`;
          },
        },
        confirmed: {
          field: "player_confirmed",
          callback: (value) => {
            return `${value}`;
          },
        },
        FechaJuego: "game_date",
        Correo: "email",
        Regional: {
          field: "meta_data",
          callback: (value) => {
            var data = "";
            if (value) {
              // var jsonData = JSON.parse(value);
              if (value.name_company) {
                data = value.name_company;
              }
            }
            return `${data}`;
          },
        },
        Beneficiario1: {
          field: "meta_data",
          callback: (value) => {
            var data = "";
            if (value) {
              // var jsonData = JSON.parse(value);
              if (value.beneficiary1) {
                data = value.beneficiary1;
              }
            }
            return `${data}`;
          },
        },
        Beneficiario2: {
          field: "meta_data",
          callback: (value) => {
            var data = "";
            if (value) {
              // var jsonData = JSON.parse(value);
              if (value.beneficiary2) {
                data = value.beneficiary2;
              }
            }
            return `${data}`;
          },
        },
        // Beneficiario3: {
        //   field: "meta_data",
        //   callback: (value) => {
        //     var data = "";
        //     if (value) {
        //       // var jsonData = JSON.parse(value);
        //       if (value.beneficiary3) {
        //         data = value.beneficiary3;
        //       }
        //     }
        //     return `${data}`;
        //   },
        // },
        // "Telefono Familiar": {
        //   field: "meta_data",
        //   callback: (value) => {
        //     var phone = "";
        //     if (value) {
        //       // var jsonPhone = JSON.parse(value);
        //       if (value.phone_family) {
        //         phone = value.phone_family;
        //       }
        //     }
        //     return `${phone}`;
        //   },
        // },
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      json_data: [],
      maintenance: true,
    };
  },
  methods: {
    closeCreatePlayers() {
      this.openModalCreatePlayers = false;
      this.serial_id = "";
    },
    closeSettings() {
      this.openModalSettings = false;
      this.serial_id = "";
    },
    handleCommand(command) {
      if (command.type === "downloadPlayers") {
        this.exportPlayer(command.data);
      }
      if (command.type === "delete") {
        this.deleteSerial(command.data);
      }
      if (command.type === "settingTables") {
        this.openModalSettings = true;
        this.serial_id = command.data.id;
      }
      if (command.type === "createPlayers") {
        this.openModalCreatePlayers = true;
        this.serial_id = command.data.id;
      }
      console.log(command);
    },
    resetSerial(indx, row) {
      let me = this;
      me.$swal
        .fire({
          target: "body",
          icon: "warning",
          title: "Estas seguro de resetear este juego?",
          html:
            "'<strong>" +
            row.serial +
            "</strong>' </br> Se restaurará el juego borrando " +
            "jugadores registrados y se colocarán todas las tablas en estado 'disponible'",
          showCancelButton: true,
          confirmButtonText: `Si, resetear`,
          cancelButtonText: `No, cancelar`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusCancel: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !me.$swal.isLoading(),
          preConfirm: () => {
            return resetSerial(row.id)
              .then(({ data }) => {
                me.getData();
                return data;
              })
              .catch(function (error) {
                me.$swal.showValidationMessage(
                  `Error: ${error.response.data.message}`
                );
              });
          },
        })
        .then((result) => {
          if (result.value) {
            me.$swal.fire({
              icon: "success",
              title: "Perfecto!",
              text: "Reseteo del juego exitoso!",
            });
          }
        });
    },
    setMaintenanceSerial() {
      setMaintenance(this.maintenance)
        .then(({ data }) => {
          console.log(data);
          this.getData();
          if (this.maintenance) {
            this.$message({
              type: "success",
              message: "Todos los juegos estan ahora HABILITADOS!",
            });
          } else {
            this.$message({
              type: "warning",
              message: "Todos los juegos estan ahora DESABILITADOS!",
            });
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    deleteSerial(row) {
      this.$swal
        .fire({
          target: "body",
          icon: "warning",
          title: "Estas seguro de eliminar este serial?",
          html: "'" + row.serial + "'",
          showCancelButton: true,
          confirmButtonText: `Si, borrar`,
          cancelButtonText: `No, cancelar`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusCancel: true,
        })
        .then((result) => {
          if (result.value) {
            deleteSerial(row.id)
              .then(({ data }) => {
                this.getData();
                this.$swal.fire(
                  "Eliminación de tablas exitosa!",
                  "",
                  "success"
                );
              })
              .catch(function (error) {
                console.log(error);
                this.loading = false;
              });
          }
        });
    },
    getData() {
      this.loading = true;
      getSerials()
        .then(({ data }) => {
          this.tableData = data;
          this.loading = false;
          if (data[0].status === "maintenance") {
            this.maintenance = false;
          } else {
            this.maintenance = true;
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    createSerial() {
      this.openModalSerial = true;
      this.editSerials = false;
    },
    editSerial(index, row) {
      this.openModalSerial = true;
      this.editSerials = true;
      this.serial_id = row.id;
    },
    openModal(row) {
      this.openModalTables = true;
      this.serial_id = row.id;
    },
    closeSerial() {
      this.openModalSerial = false;
      this.editSerials = false;
      this.serial_id = "";
    },
    exportPlayer(row) {
      exportPlayer(row.id)
        .then(({ data }) => {
          this.json_data = data;
          this.openModalDownload = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    finishDownload() {
      this.json_data = [];
      this.openModalDownload = false;
    },
    closeModalDownload() {
      this.openModalDownload = false;
    },
    downloadExcel(data) {},
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
  mounted() {
    if (this.show) {
      this.getData();
    }
  },
};
</script>
<style lang="css">
.el-table__expanded-cell {
  padding: 0 10px 10px 60px !important;
}
.total_players {
  font-weight: bold;
}
</style>