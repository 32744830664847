<template>
  <div>
    <el-button v-if="!loading" class="" type="primary" @click="openCheckoutEpayco"
      :loading="loadingPayment"><i class="mdi mdi-shield-check-outline"></i> Paga con <strong>ePayco</strong></el-button>
    <el-button v-else type="primary" icon="el-icon-info" :loading="loading">Esperando comfirmacion del
      pago...</el-button>
  </div>
</template>
<script>
import axios from 'axios';
import { getInvoices } from "@/api/player";
import { update, updateTransactions } from "@/api/invoice";
export default {
  name: 'ButtonEpayco',
  props: ['dataplayer', 'datacheckout', 'loading', 'invoice'],
  watch: {
    "invoice": {
      handler: function (nuevoValor, antiguoValor) {
        //
      },
      deep: true // Observa cambios profundos en el objeto
    },
  },
  mounted() {
    this.createWidgetCheckoutEpayco()
  },
  data() {
    return {
      loadingPayment: false,
      checkoutEpayco: null,
      dataEpayco: null,
    }
  },
  methods: {
    async createWidgetCheckoutEpayco() {
      let me = this
      console.log('createWidgetCheckoutEpayco');
      let urlConfirmation = process.env.VUE_APP_ROOT + 'jugar/payment-table/' + me.dataplayer.id + '/' + me.dataplayer.confirmation_code
      let urlResponse = process.env.VUE_APP_ROOT + 'jugar/payment-table/' + me.dataplayer.id + '/' + me.dataplayer.confirmation_code
      // let urlConfirmation = 'http://localhost:8080/jugar/payment-table/' + me.dataplayer.id + '/' + me.dataplayer.confirmation_code
      // let urlResponse = 'http://localhost:8080/jugar/payment-table/' + me.dataplayer.id + '/' + me.dataplayer.confirmation_code
      this.dataEpayco = {
        //Parametros compra (obligatorio)
        name: "Tablas de bingo Comfandi",
        description: "Tablas de bingo Comfandi",
        invoice: me.datacheckout.consecutive,
        currency: me.datacheckout.currency.toLowerCase(),
        amount: me.datacheckout.ammount / 100,
        tax_base: "0",
        tax: "0",
        tax_ico: "0",
        country: "co",
        lang: "es",

        //Onpage="false" - Standard="true"
        external: "false",


        //Atributos opcionales
        extra1: "extra1",
        extra2: "extra2",
        extra3: "extra3",
        confirmation: urlConfirmation,
        response: urlResponse,

        //Atributos cliente
        name_billing: me.dataplayer.name_player,
        address_billing: "",
        type_doc_billing: "cc",
        mobilephone_billing: me.dataplayer.phone,
        number_doc_billing: me.dataplayer.nit,

        //atributo deshabilitación método de pago
        methodsDisable: ["CASH"]

      }
      this.checkoutEpayco = await ePayco.checkout.configure({
        key: '45b960805ced5c27ce34b1600b4b9f54',
        test: true
      });
    },
    openCheckoutEpayco() {
      let me = this
      this.checkoutEpayco.open(me.dataEpayco)
    },
    async checkTransactionEpayco(data) {
      let me = this
      let invoiceId = data.data.invoice.id
      let transactionId = data.data.invoice.transaction_id
      console.log('checkTransactionEpayco');
      // await axios.get('https://sandbox.wompi.co/v1/transactions/' + transactionId)
      //   .then(async response => {
      //     if (response.data.data.status === "APPROVED") {
      //       await me.updateTransactionByWompi(invoiceId, response.data.data)
      //     } else {
      //       if (response.data.data.status === "PENDING") {
      //         this.$message({
      //           type: "info",
      //           message: "Tu transacción esta en estado PENDIENTE",
      //         });
      //       } else {
      //         me.loadingPayConfirmation = false
      //         await me.updateTransactionByWompi(invoiceId, response.data.data)
      //         this.$message({
      //           type: "warning",
      //           message: "Tu transacción no fue aprobada",
      //         });
      //       }
      //     }
      //   })
      //   .catch(error => {
      //     console.error('Error al consultar la transaccion en Wompi:', error);
      //   });
    },
    updateTransactionInvoice(transaction) {
      let me = this
      this.loadingPayment = true;
      let data = {
        "environment": me.datacheckout.environment,
        "transaction_id": transaction.id,
        "transaction_status": transaction.status,
        "transaction_payment_method_type": transaction.paymentMethodType
      }
      update(this.dataplayer.invoice.id, data)
        .then(({ data }) => {
          this.loadingPayment = false;
          console.log(data);
          me.$emit('transaction', data.data)
          setTimeout(function () {
            location.reload();
          }, 2000);
        })
        .catch(function (error) {
          me.loadingPayment = false
          console.log("Err: ", error);
        });
    },
    updateTransactionByWompi(invoiceId, transaction) {
      let me = this
      let data = {
        "environment": me.datacheckout.environment,
        "transaction_id": transaction.id,
        "transaction_status": transaction.status,
        "transaction_payment_method_type": transaction.payment_method_type
      }
      updateTransactions(invoiceId, data)
        .then(({ data }) => {
          this.loadingPayConfirmation = false;
          console.log(data);
          if (transaction.status === "APPROVED") {
            this.$message({
              type: "success",
              message: "transacción aprobada!!, por favor descarga tus tablas!!",
            });
            me.$emit("show-download", data)
          }
        })
        .catch(function (error) {
          me.loadingPayConfirmation = false
          console.log("Err: ", error);
        });
    }
  },
}
</script>