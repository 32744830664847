import Vue from 'vue'
import Router from 'vue-router'

import {
  getUser
} from '@/utils/auth'

//apps
import Maintenance from '../views/pages/errors/Maintenance.vue'
import Dashboard from '../views/apps/Dashboard.vue'
import Verify from "@/views/pages/authentication/Verify.vue";
import Login from "@/views/pages/authentication/Login.vue";
import Administration from "@/views/apps/modules/Administration.vue";
import PlayerAgency from "@/views/apps/modules/PlayerAgency.vue";
import PaymentTable from "@/views/apps/modules/invoice/PaymentTable.vue";

//errors
import errors from './modules/errors'

import layouts from '../layout'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/jugar',
  routes: [{
      path: '/',
      alias: '/dashboards',
      name: 'Inicio',
      component: Dashboard,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.contenOnly,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin', 'support'],
        icon: 'home-outline'
      }
    },
    {
      path: '/register/:code_agency',
      alias: '/register',
      name: 'Registro de agnecias',
      component: PlayerAgency,
      meta: {
        auth: false,
        nav: false,
        layout: layouts.contenOnly,
        searchable: true,
        tags: ['Agencias'],
        roles: ['admin', 'support'],
        icon: 'home-outline'
      }
    },
    {
      path: '/administration',
      alias: '/administration',
      name: 'Administracion',
      component: Administration,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin', 'support'],
        icon: 'home-outline'
      }
    },
    {
      path: '/login',
      alias: '/login',
      name: 'Iniciar Sesión',
      component: Login,
      meta: {
        auth: false,
        nav: true,
        layout: layouts.navTop,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'account-arrow-left-outline'
      }
    },
    {
      path: '/verify/:id/:code',
      alias: '/verify',
      name: 'Verificación',
      component: Verify,
      meta: {
        auth: false,
        nav: false,
        layout: layouts.contenOnly,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'account-arrow-left-outline'
      }
    },
    {
      path: '/payment-table/:id/:code',
      alias: '/payment-table',
      name: 'Payment',
      component: PaymentTable,
      meta: {
        auth: false,
        nav: false,
        layout: layouts.contenOnly,
        searchable: true,
        tags: ['Inicio'],
        roles: ['admin'],
        icon: 'account-arrow-left-outline'
      }
    },
    {
      path: '/logout',
      name: 'Cerrar Sesion',
      beforeEnter(to, from, next) {
        auth.logout()
        next({
          path: '/dashboards'
        })
      }
    },
    {
      path: '/maintenance',
      alias: '/maintenance',
      name: 'Mantenimiento',
      component: Maintenance,
      meta: {
        auth: false,
        nav: false,
        layout: layouts.contenOnly,
      }
    },
    errors,
  ]
})


const l = {
  contenOnly() {
    store.commit('setLayout', layouts.contenOnly)
  },
  navLeft() {
    store.commit('setLayout', layouts.navLeft)
  },
  navRight() {
    store.commit('setLayout', layouts.navRight)
  },
  navTop() {
    store.commit('setLayout', layouts.navTop)
  },
  navBottom() {
    store.commit('setLayout', layouts.navBottom)
  },
  set(layout) {
    store.commit('setLayout', layout)
  }
}

//insert here login logic
const auth = {
  loggedIn() {
    // return true
    return store.getters.isLogged
  },
  logout() {
    store.commit('setLogout')
  }
}

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.auth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn()) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    if (to.path === '/login' && auth.loggedIn()) {
      next({
        path: '/administration'
      });
      return;
    }
    next() // make sure to always call next()!
  }
  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout)
  }
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit('setSplashScreen', false)
  }, 500)
})

export default router
