import request from "@/utils/request";

const routePath = "invoice/category";

export function store(data) {
  return request({
    url: routePath + "/store",
    method: "post",
    data,
  });
}

export function update(categoryId, data) {
  return request({
    url: routePath + "/update/" + categoryId,
    method: "put",
    data
  });
}

export function deleteData(id) {
  return request({
    url: routePath + "/delete/" + id,
    method: "delete"
  });
}

export function getAll(page_size, currentPage, filters) {
  return request({
    url: routePath + "/all/" + page_size + '?page=' + currentPage,
    method: "get",
    data: filters
  });
}

export function getById(id) {
  return request({
    url: routePath + "/getById/" + id,
    method: "get"
  });
}
