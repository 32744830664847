<template>
  <vue-scroll class="register-page align-vertical">
    <div
      class="form-wrapper align-vertical-middle"
      v-loading="loading_table"
      :element-loading-text="text_loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <img class="image-logo" :src="pathImagesUrl + '/banner.png'" alt="logo" />
      <div class="no-play" v-if="verify_data && !loading_verify">
        Bingo!!
        <br />
        <div class="return-play">
           Tu registro ha sido verificado correctamente.
        </div>
        <div class="return-play">
          Descarga ya tu(s) tabla(s) y no te quedes sin Jugar!
        </div>
        <el-button
          class="btn-download-table"
          type="primary"
          icon="el-icon-download"
          @click="downloadTable"
          :loading="loading_download"
          >Descargar Tabla!</el-button
        >
      </div>
      <div class="no-play" v-if="!verify_data && !loading_verify">
        No pudimos verificar tu registro :(
        <br />
        <span class="return-play">{{ msn }}</span>
      </div>
      <div v-if="loading_verify" class="loading-game">
        <i class="el-icon-loading"></i> Validando registro...
      </div>
      <button-return-register></button-return-register>
    </div>
  </vue-scroll>
</template>
<script>
import ButtonReturnRegister from "@/views/apps/modules/ButtonReturnRegister.vue";
import { getTable, verify, downloadTable } from "@/api/player";
import { setPlayer } from "@/utils/player";
export default {
  name: "Verify",
  components: {
    ButtonReturnRegister
  },
  data() {
    return {
      pathImagesUrl: process.env.VUE_APP_ROOT + 'images/' + process.env.VUE_APP_FOLDER_IMAGES,
      text_loading: "",
      loading_download: false,
      loading_table: false,
      loading_verify: true,
      verify_data: false,
      table: { table_bingo: "" },
      msn: "",
    };
  },
  beforeMount() {
    // verificacion del email
    this.verify();
  },
  methods: {
    downloadTable() {
      this.loading_download = true;
      downloadTable(this.table)
        .then(({ data }) => {
          this.downloadFile(data, "Mis tablas de bingo");
        })
        .catch(function (error) {
          this.loading_download = false;
          this.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      this.loading_download = false;
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    tableNumber() {
      let me = this;
      this.$prompt(
        "Por favor ingrese su número de documento o email",
        "Ingresa a jugar",
        {
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
          type: "danger",
          inputType: "text",
          inputPlaceholder: "Número de documento - Email",
          inputErrorMessage: "Ingresa Documento o Email",
          inputValidator: function (val) {
            if (val === null) {
              return false;
            } else {
              return true;
            }
          },
        }
      ).then(({ value }) => {
        this.getTableBingo(value);
      });
    },
    getTableBingo(value) {
      this.text_loading = "Cargando Tabla...";
      this.loading_table = true;
      getTable(value)
        .then(({ data }) => {
          this.loading_table = false;
          if (data.player) {
            if (data.player.confirmed !== 0) {
              setPlayer(JSON.stringify(data.player));
              this.$router.push({ path: "/" });
              location.reload();
            } else {
              this.$swal.fire({
                icon: "warning",
                title: "La verificación por email no se ha completado!!",
                html:
                  "Por favor confirma tu registro desde el email enviado a <strong>" +
                  data.player.email +
                  "</strong> haciendo click en el vínculo, verificar email",
                showConfirmButton: true,
              });
            }
          } else {
            this.$message({
              type: "warning",
              message: "No hay registros con ese código de tabla",
            });
          }
        })
        .catch(function (error) {
          this.loading_table = false;
          this.$message.error("Error.");
          console.log(error);
        });
    },
    verify() {
      var params = this.$route.params;
      verify(params.id, params.code)
        .then(({ data }) => {
          this.loading_verify = false;
          if (data.data) {
            this.verify_data = true;
            this.table = data.table;
          } else {
            this.msn = data.error.errorInfo[2];
            this.verify_data = false;
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.register-page {
  .btn-download-table {
    font-size: 20px;
  }
  .return-play {
    font-size: 25px;
    color: #0868AE;
    font-weight: 900;
  }
  .no-play {
    text-align: center;
    color: #da5635;
    border: 2px solid #DBDBEA;
    border-radius: 30px;
    font-size: 40px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 40px;
    margin-top: 50px;
    width: 400px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }
  .loading-game {
    color: #0868AE;
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
    margin-bottom: 20px;
  }
  // .return-play {
  //   font-size: 25px;
  //   color: #ffee78;
  //   font-weight: 900;
  // }
  // .return-play:hover {
  //   color: #ffdf00;
  // }


  .accept {
    margin-top: 20px;
  }
  .form-wrapper {
    width: 100%;
    text-align: center;
  }

  .image-logo {
    width: 300px;
    margin: 0px auto;
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.register-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }

  .register-page{
    .image-logo{
      margin-top: 0px;
    }
  }
}

@media (max-width: 550px) {
  .register-page {
    .no-play {
      width: auto;
    }
  }
}
@media (max-width: 390px) {
  .register-page {
    .no-play {
      font-size: 22px;
      .return-play{
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 330px) {
  .register-page{
    .image-logo{
      width: 200px;
    }
    .btn-download-table {
      font-size: 14px;
    }
  }
}
</style>
