import request from "@/utils/request";

const routePath = "invoice";

export function store(data) {
  return request({
    url: routePath + "/store",
    method: "post",
    data,
  });
}

export function update(invoiceId, data) {
  return request({
    url: routePath + "/update/" + invoiceId,
    method: "put",
    data,
  });
}

export function updateTransactions(invoiceId, data) {
  return request({
    url: routePath + "/update/" + invoiceId,
    method: "put",
    data,
  });
}

export function deleteData(id) {
  return request({
    url: routePath + "/delete/" + id,
    method: "delete",
  });
}

export function getAll(page_size, currentPage, consecutive, status, nit) {
  return request({
    url:
      routePath + "/all/" + page_size + "/" + consecutive + "/" + status + "/"+ nit +"?page=" + currentPage,
    method: "get",
  });
}

export function getById(id) {
  return request({
    url: routePath + "/getById/" + id,
    method: "get",
  });
}
