<template>
  <div>
    <el-dialog
      width="50%"
      top="40px"
      :visible.sync="modalOpenSerial"
      :before-close="closeModal"
      :destroy-on-close="true"
    >
      <div slot="title">
        <i :class="edit === true ? 'el-icon-edit' : 'el-icon-tickets'"></i>
        {{ title }}
        <i class="fa fa-edit"></i>
      </div>
      <!-- el-icon-tickets -->
      <el-form
        :model="form"
        :rules="rules"
        v-loading="loadingForm"
        size="mini"
        ref="form"
        label-width="200px"
        class="demo-form"
      >
        <el-form-item label="N° Serial" prop="serial">
          <el-input v-model="form.serial"></el-input>
        </el-form-item>
        <el-form-item label="Estado" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="Abierto"></el-radio>
            <el-radio label="Cerrado"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Tablas por jugador" prop="quantity_tables">
          <label slot="label">Max. Tablas por jugador</label>
          <el-input-number
            v-model="form.quantity_tables"
            :min="1"
            :max="max_quantity_tables_selected"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="select_number_tables">
          <label slot="label"
            >Seleccionar tablas
            <el-popover
              placement="top-start"
              title="Información"
              width="250"
              trigger="hover"
              content="Permite al jugador seleccionar la cantidad de tablas que desee, hasta el maximo permitido"
            >
              <i
                slot="reference"
                class="el-icon-info"
                style="color: #f7ba2a; cursor: pointer"
              ></i>
            </el-popover>
          </label>
          <el-switch
            v-model="form.select_number_tables"
            active-text="Si"
            inactive-text="No"
          >
          </el-switch>
          <el-alert
            title="Permite a los jugadores seleccionar la cantidad de tablas que deseen"
            type="warning"
          >
          </el-alert>
        </el-form-item>
        <el-form-item prop="select_number_tables">
          <label slot="label"
            >Pago de tabla
            <el-popover
              placement="top-start"
              title="Información"
              width="250"
              trigger="hover"
              content="El jugador tendra que realizar un pago exitoso para que el sistema genere sus tablas, las tarifas por tabla estan en el menú de Facturas/Categorias"
            >
              <i
                slot="reference"
                class="el-icon-info"
                style="color: #f7ba2a; cursor: pointer"
              ></i>
            </el-popover>
          </label>
          <el-switch
            v-model="form.enable_payment"
            active-text="Habilitado"
            inactive-text="Desabilitado"
            @change="handleEnablePayment"
          >
          </el-switch>
          <el-alert
            title="Si habilitas esta opción, debes configurar la confirmacion por email"
            type="warning"
          >
          </el-alert>
        </el-form-item>
        <el-form-item label="Confirmación por email" prop="confirmation_email">
          <label slot="label"
            >Confirmación por email
            <el-popover
              placement="top-start"
              title="Información"
              width="250"
              trigger="hover"
              content="Activar asignación de tabla mediante la confirmación de email"
            >
              <i
                slot="reference"
                class="el-icon-info"
                style="color: #f7ba2a; cursor: pointer"
              ></i>
            </el-popover>
          </label>
          <el-switch
            v-model="form.confirmation_email"
            active-text="Si"
            inactive-text="No"
          >
          </el-switch>
          <transition name="fade">
            <div v-if="form.confirmation_email">
              <el-alert
                title="Ingresa los datos de configuración de Email"
                type="info"
                show-icon
                :closable="false"
              >
              </el-alert>
              <el-row :gutter="10" class="mail_config">
                <el-col :span="12">
                  <el-form-item prop="mail.host">
                    <el-input
                      type="text"
                      placeholder="Host"
                      v-model="form.mail.host"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="mail.port">
                    <el-input
                      type="text"
                      placeholder="Puerto"
                      v-model="form.mail.port"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="mail.encryption">
                    <el-select
                      v-model="form.mail.encryption"
                      placeholder="Encriptación"
                      clearable
                    >
                      <el-option
                        v-for="item in encryptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item prop="mail.username">
                    <el-input
                      type="text"
                      placeholder="Nombre de usuario (mail)"
                      v-model="form.mail.username"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="mail.password">
                    <el-input
                      type="text"
                      placeholder="Contraseña (mail)"
                      v-model="form.mail.password"
                      clearable
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </transition>
        </el-form-item>
        <el-form-item label="Fecha de inicio" class="mb-0" required>
          <el-col :span="11">
            <el-form-item prop="start_date">
              <el-date-picker
                type="date"
                placeholder="Selecciona"
                v-model="form.start_date"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item prop="start_time">
              <el-time-picker
                placeholder="Ingresa Hora"
                v-model="form.start_time"
                style="width: 100%"
                format="hh:mm:ss A"
                value-format="hh:mm:ss A"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="Premio" class="mb-0" required>
          <el-col :span="11">
            <el-form-item prop="variable_prize">
              <el-input
                type="text"
                placeholder="Variable"
                v-model="form.variable_prize"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item prop="fixed_prize">
              <el-input
                type="text"
                placeholder="Fijo"
                v-model="form.fixed_prize"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="URL de Transmisión" prop="url_transmission">
          <el-input
            type="text"
            placeholder="https://"
            v-model="form.url_transmission"
          ></el-input>
        </el-form-item>
        <el-form-item label="Terminos y condiciones" prop="terms">
          <el-input
            type="text"
            placeholder="https://"
            v-model="form.terms"
          ></el-input>
        </el-form-item>
        <el-form-item label="Icono del Canal" prop="channel_icon">
          <el-input type="text" v-model="form.channel_icon"></el-input>
        </el-form-item>

        <el-form-item label="Observación" prop="observation">
          <el-input
            type="textarea"
            placeholder="Ingrese una observación"
            v-model="form.observation"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verify_player_option">
          <label slot="label"
            >Archivo de validación para registro de jugadores
            <el-popover
              placement="top-start"
              title="Información"
              width="250"
              trigger="hover"
              content="Los jugadores se podran registrar únicamente si su número de documento está registrado en este archivo"
            >
              <i
                slot="reference"
                class="el-icon-info"
                style="color: #f7ba2a; cursor: pointer"
              ></i>
            </el-popover>
          </label>
          <el-switch
            v-model="form.verify_player_option"
            style="margin: 5px 0px 10px; padding-right: 10px"
          >
          </el-switch>
          <span>
            Descarga archivo demo
            <el-link type="primary" :href="baseURLfile"
              >aquí <i class="el-icon-download"></i
            ></el-link>
          </span>
          <transition name="fade">
            <el-upload
              v-if="form.verify_player_option"
              drag
              class="upload-demo"
              ref="upload"
              :action="baseURL"
              :limit="1"
              :on-change="fileChange"
              :on-remove="fileRemove"
              :on-success="submitComplete"
              :on-error="submitError"
              :auto-upload="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Suelta tu archivo aquí o <em>haz clic para cargar</em>
              </div>
              <div slot="tip" class="el-upload__tip">
                Solo archivos xlsx con un tamaño menor de 5Mb
              </div>
            </el-upload>
          </transition>
          <el-alert
            v-if="form.file_verify_player_option !== null"
            show-icon
            title="customized close-text"
            type="success"
            close-text="Borrar X"
            @close="deleteFile"
          >
            <template slot="title">
              <span
                ><i class="el-icon-document"></i>
                {{ form.file_verify_player_option }}</span
              >
            </template>
          </el-alert>
        </el-form-item>
        <el-form-item prop="verify_by_table_code">
          <label slot="label"
            >Validar por codigo de tabla
            <el-popover
              placement="top-start"
              title="Información"
              width="250"
              trigger="hover"
              content="Permite validar las tablas por medio del código asignado a cada tabla antes de realizar el registro del jugador"
            >
              <i
                slot="reference"
                class="el-icon-info"
                style="color: #f7ba2a; cursor: pointer"
              ></i>
            </el-popover>
          </label>
          <el-switch
            v-model="form.verify_by_table_code"
            active-text="Si"
            inactive-text="No"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" icon="el-icon-close" @click="closeModal"
          >Cancelar</el-button
        >
        <el-button
          v-if="edit === true"
          size="mini"
          icon="el-icon-edit"
          type="warning"
          @click="submitForm('form')"
          >Editar</el-button
        >
        <el-button
          v-else
          size="mini"
          icon="el-icon-check"
          type="primary"
          @click="submitForm('form')"
          >Registrar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { store, update, getSerialById, deletePlayerVerify } from "@/api/serial";
export default {
  name: "SerialCreate",
  props: ["modalOpenSerial", "serial_id", "edit"],
  watch: {
    edit: function (val) {
      if (val) {
        this.title = "Editar Serial";
        this.getSerial();
      } else {
        this.title = "Registrar Serial";
      }
    },
  },
  data() {
    return {
      loadingForm: false,
      title: "Registrar Serial",
      fileList: [],
      showFileUpload: false,
      encryptions: [
        {
          value: "tls",
          label: "TLS",
        },
        {
          value: "ssl",
          label: "SSL",
        },
      ],
      form: {
        serial: "",
        status: "Abierto",
        quantity_tables: 1,
        start_date: "",
        start_time: "",
        url_transmission: "",
        channel_icon: "",
        variable_prize: "",
        fixed_prize: 0,
        observation: "",
        terms: "",
        verify_player_option: false,
        file_verify_player_option: "",
        confirmation_email: false,
        verify_by_table_code: false,
        mail: {
          host: "",
          port: "",
          encryption: "tls",
          username: "",
          password: "",
        },
        select_number_tables: false,
        enable_payment: false,
      },
      rules: {
        serial: [
          {
            required: true,
            message: "Campo obligatorio",
            trigger: "blur",
          },
          {
            min: 5,
            max: 20,
            message: "Longitud de 5 a 20 caracteres",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            type: "string",
            required: true,
            message: "Selecciona una fecha",
            trigger: "change",
          },
        ],
        url_transmission: [
          {
            required: true,
            message: "Campo obligatorio",
            trigger: "blur",
          },
        ],
        variable_prize: [
          {
            required: true,
            message: "Campo obligatorio",
            trigger: "blur",
          },
        ],
      },
      baseURLfile:
        process.env.VUE_APP_ROOT + "assets/app/download/JUGADORES.xlsx",
      baseURL: "",
      max_quantity_tables_selected: 1,
    };
  },
  methods: {
    handleEnablePayment(data){
      if (!this.form.confirmation_email) {
        this.form.confirmation_email = true
      }
    },
    fileChange(file, fileList) {
      this.fileList.push(file);
      if (this.fileList.length < 1) {
        this.loadingForm = false;
        this.$message({
          type: "warning",
          message:
            "Es necesario cargar un archivo de validación, cuando esta habilitada la opción. ",
        });
        return true;
      }
    },
    fileRemove(file, fileList) {
      this.fileList = [];
    },
    submitUpload(serial_id) {
      let me = this;
      this.loadingForm = true;
      this.baseURL =
        process.env.VUE_APP_ROOT_API + "/serial/uploadDocument/" + serial_id;
      setTimeout(() => {
        console.log("entro");
        me.$refs.upload.submit();
      }, 1000);
    },
    submitComplete(response, file, fileList) {
      let me = this;
      me.loadingForm = false;
      if (response.code === 200) {
        me.$message({
          type: "success",
          message: "Carga de archivo exitosa!",
        });
        me.closeModal(true);
      } else {
        me.$message({
          type: "warning",
          message: "Error: " + response.response.data,
        });
      }
    },
    submitError(error, file, fileList) {
      this.loadingForm = false;
      this.$message({
        type: "error",
        message:
          "Error al cargar archivo: Verifica que los datos del archivo" +
          " esten correctamente digitados, que no falten columnas o que no se repitan registros." +
          error.message.substring(48, 200) +
          "....",
        duration: 10000,
      });
      if (error.response) {
        // Request made and server responded
        console.log("Err1: ", error.response.data);
        console.log("Err2: ", error.response.status);
        console.log("Err3: ", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request: ", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error: ", error.message);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.form.verify_player_option &&
            this.fileList.length < 1 &&
            !this.showFileUpload
          ) {
            this.$message({
              type: "warning",
              message:
                "Es necesario cargar un archivo de validación, cuando esta habilitada la opción. ",
            });
          } else {
            if (this.edit === true) {
              this.editSerial();
            } else {
              this.register();
            }
          }
        } else {
          this.$message({
            type: "warning",
            message: "Completa los campos obligatorios",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      let me = this;
      this.$refs[formName].resetFields();
      setTimeout(() => {
        me.fileList = [];
      }, 200);
    },
    register() {
      this.loadingForm = true;
      store(this.form)
        .then(({ data }) => {
          this.loadingForm = false;
          if (data.verify_player_option) {
            this.submitUpload(data.id);
          } else {
            this.closeModal(true);
          }
        })
        .catch(function (error) {
          this.loadingForm = false;
          console.log(error);
        });
    },
    editSerial() {
      this.loadingForm = true;
      update(this.serial_id, this.form)
        .then(({ data }) => {
          this.loadingForm = false;
          console.log(this.fileList.length);
          if (data.verify_player_option && this.fileList.length >= 1) {
            this.submitUpload(data.id);
          } else {
            this.closeModal(true);
          }
        })
        .catch(function (error) {
          this.loadingForm = false;
          console.log(error);
        });
    },
    getSerial() {
      this.loadingForm = true;
      getSerialById(this.serial_id)
        .then(({ data }) => {
          let mailConfig = JSON.parse(data.confirmation_email_configuration);
          this.form = {
            serial: data.serial,
            status: data.status === "open" ? "Abierto" : "Cerrado",
            start_date: data.start_date,
            start_time: data.start_time,
            url_transmission: data.url_transmission,
            channel_icon: data.channel_icon,
            variable_prize: data.variable_prize,
            fixed_prize: data.fixed_prize,
            observation: data.observation,
            terms: data.terms,
            quantity_tables: data.quantity_tables,
            select_number_tables: data.select_number_tables ? true : false,
            verify_player_option: data.verify_player_option ? true : false,
            file_verify_player_option: data.file_verify_player_option,
            confirmation_email: data.confirmation_email === 1 ? true : false,
            verify_by_table_code: data.verify_by_table_code === 1 ? true : false,
            enable_payment: data.enable_payment === 1 ? true : false,
            mail: {
              host: mailConfig ? mailConfig.host : "",
              port: mailConfig ? mailConfig.port : "",
              encryption: mailConfig ? mailConfig.encryption : "tls",
              username: mailConfig ? mailConfig.username : "",
              password: mailConfig ? mailConfig.password : "",
            },
          };
          this.max_quantity_tables_selected = (data.max_quantity_tables_selected) ? data.max_quantity_tables_selected : 1,
          this.showFileUpload =
            data.file_verify_player_option !== null &&
            data.file_verify_player_option !== ""
              ? true
              : false;
          this.loadingForm = false;
        })
        .catch(function (error) {
          console.log(error);
          this.loadingForm = false;
        });
    },
    closeModal(refresh) {
      this.resetForm("form");
      this.$emit("close");
      if (refresh === true) {
        this.$emit("refresh");
      }
    },
    deleteFile() {
      let me = this;
      me.$swal
        .fire({
          target: "body",
          icon: "warning",
          title: "Estas seguro que desea eliminar los archivos y registros cargados?",
          html:
            "</br> No se podran restaurar",
          showCancelButton: true,
          confirmButtonText: `Si, eliminar`,
          cancelButtonText: `No, cancelar`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusCancel: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !me.$swal.isLoading(),
          preConfirm: () => {
            me.loadingForm = true;
            return deletePlayerVerify(me.serial_id)
              .then(({ data }) => {
                me.showFileUpload = false;
                me.loadingForm = false;
              })
              .catch(function (error) {
                me.loadingForm = false;
                console.log(error);
              });
          },
        })
        .then((result) => {
          if (result.value) {
            me.$swal.fire({
              icon: "success",
              title: "Perfecto!",
              text: "Se eliminaron los archivos y registros cargados!",
            });
          }else{
            me.getSerial();
          }
        });
    },
  },
};
</script>

<style lang="css">
.el-alert__content {
  padding: 0 48px 0 8px;
}
</style>

<style lang="scss" scope>
.line {
  text-align: center;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.el-alert .el-alert__description {
  margin: 0px !important;
}
.el-popover {
  overflow: hidden !important;
}
.mail_config {
  margin-top: 10px;
}
</style>