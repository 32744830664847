<template>
  <el-button size="mini" icon="el-icon-arrow-left" @click="removeDataSession">Volver a la pagina de registro</el-button>
</template>
<script>
import { removePlayer, removeBingo } from "@/utils/player";
export default {
  name: "ButtonReturnRegister",
  data() {
    return {
      //
    }
  },
  methods: {
    removeDataSession() {
      removeBingo()
      removePlayer()
      this.$router.push('/');
      setTimeout(() => {
        location.reload();
      }, 500);
    }
  },
}
</script>