import request from '@/utils/request'

export function getAvailablePlay() {
  return request({
    url: 'player/getAvailablePlay',
    method: 'post'
  });
}

export function store(data) {
  return request({
    url: 'player/store',
    method: 'post',
    data
  });
}

export function validateEmail(data) {
  return request({
    url: 'player/validateEmail',
    method: 'post',
    data
  });
}

export function validateNit(data) {
  return request({
    url: 'player/validateNit',
    method: 'post',
    data
  });
}

export function getPlayer(id) {
  return request({
    url: 'player/getPlayer/' + id,
    method: 'get'
  });
}

export function getTable(code) {
  return request({
    url: 'player/getTable/' + code,
    method: 'get'
  });
}

export function getCities() {
  return request({
    url: 'player/getCities',
    method: 'get'
  });
}

export function getPlayers(page_size, currentPage, nit, email, table, tableCode) {
  return request({
    url: 'player/getPlayers/' + page_size + '/' + nit + '/' + email + '/' + table + '/' + tableCode + '?page=' + currentPage,
    method: 'get'
  });
}

export function verify(id, code) {
  return request({
    url: 'player/verify/' + id + '/' + code,
    method: 'get'
  });
}

export function resendEmail(email) {
  return request({
    url: 'player/resendEmail/' + email,
    method: 'get'
  });
}

export function deletePlayer(id) {
  return request({
    url: 'player/deletePlayer/' + id,
    method: 'delete'
  });
}

export function downloadTable(data) {
  return request({
    url: 'player/downloadTable',
    method: 'post',
    data,
    responseType: 'arraybuffer'
  });
}

export function validateCodeAgency(data) {
  return request({
    url: 'player/validateCodeAgency',
    method: 'post',
    data
  });
}

export function getCode(code) {
  return request({
    url: 'player/getCode/' + code,
    method: 'get'
  });
}

export function changePayTable(data) {
  return request({
    url: 'player/changePayTable/' + data.id,
    method: 'post',
    data
  });
}

export function getInvoices(id, code) {
  return request({
    url: 'player/get-invoices/' + id + '/' + code,
    method: 'get'
  });
}

export function assignTable(data) {
  return request({
    url: 'player/payment-table',
    method: 'post',
    data
  });
}