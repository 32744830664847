<template>
  <el-card class="box-card" v-loading="loadingDownload" element-loading-text="Descargando Tabla..."
    element-loading-spinner="el-icon-loading" element-loading-background="rgb(255 255 255 / 80%)">
    <div slot="header" class="clearfix">
      <span> <i class="el-icon-document"></i> Lista de Facturas </span>
    </div>
    <el-card class="card-shadow--small">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="#factura" size="mini" v-model="consecutive" clearable @clear="
            consecutive = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="Documento jugador" size="mini" v-model="nit" clearable @clear="
            nit = null;
          getData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-select v-model="status" placeholder="Estado" size="mini" clearable @clear="
            status = null;
          getData();
          ">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
              <el-link :type="item.type">
                <i :class="'el-icon-' + item.icon"></i> {{ item.label }}
              </el-link>
            </el-option>
          </el-select>

        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" size="mini" :loading="loadingFilter"
            @click="filterTable">Buscar</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-table :data="tableData" v-loading="loading" height="400" style="width: 100%">
      <el-table-column prop="consecutive" label="# Factura" width="110"></el-table-column>
      <el-table-column prop="player" label="Jugador" width="270">
        <template slot-scope="scope">
          <div><i class="el-icon-user"></i> {{ scope.row.player.name_player }}</div>
          <small><i class="el-icon-postcard"></i> {{ scope.row.player.nit }}</small>&nbsp;
          <small><i class="el-icon-phone-outline"></i> {{ scope.row.player.phone }}</small><br>
          <small><i class="el-icon-message"></i> {{ scope.row.player.email }}</small>
        </template>
      </el-table-column>
      <el-table-column prop="transaction_payment_method_type" label="Metodo de pago" width="220"></el-table-column>
      <el-table-column prop="transaction_ammount" label="Valor" width="100">
        <template slot-scope="scope">
          <div>$ {{ formatN(scope.row.transaction_ammount) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="categorie" label="Categoria" width="130">
        <template slot-scope="scope">
          <div><i class="el-icon-discount"></i> {{ setCategory(scope.row.categorie).category }}</div>
          <div>Subsidio: {{ setCategory(scope.row.categorie).subsidy }}%</div>
        </template>
      </el-table-column>
      <el-table-column label="Estado" width="130">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.transaction_status === 'APPROVED'">
            <i class="el-icon-circle-check"></i> APROBADA
          </el-tag>
          <el-tag type="warning" size="small" v-if="scope.row.transaction_status === 'PENDING'">
            <i class="el-icon-warning-outline"></i> PENDIENTE
          </el-tag>
          <el-tag type="info" size="small" v-if="scope.row.transaction_status === 'VOIDED'">
            <i class="el-icon-info"></i> ANULADA
          </el-tag>
          <el-tag type="danger" size="small" v-if="scope.row.transaction_status === 'DECLINED'">
            <i class="el-icon-circle-close"></i> RECHAZADA
          </el-tag>
          <el-tag type="danger" size="small" v-if="scope.row.transaction_status === 'ERROR'">
            <i class="el-icon-circle-close"></i> ERROR
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Acciones" width="90" class="align-vertical-middle">
        <template slot-scope="scope">
          <el-popconfirm confirmButtonText="Si" cancelButtonText="No, Cancelar" icon="el-icon-info" iconColor="red"
            title="Deseas eliminar este registro?" @confirm="deleteInvoice(scope.row)">
            <el-button slot="reference" size="mini" type="danger" circle>
              <i class="el-icon-delete"></i>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column> -->
    </el-table>
    <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
  </el-card>
</template>
<script>
import { getAll, deleteData } from "@/api/invoice";
import { formatNumber } from "@/utils/number";
export default {
  name: "InvoiceList",
  props: ["show"],
  watch: {
    show: function (val) {
      if (val) {
        this.getData();
      }
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      loadingFilter: false,
      loadingDownload: false,
      search: "",
      statusOptions: [{
        value: 'APPROVED',
        label: 'APROBADA',
        type: "success",
        icon: "circle-check",
      }, {
        value: 'PENDING',
        label: 'PENDIENTE',
        type: "warning",
        icon: "warning-outline",
      }, {
        value: 'VOIDED',
        label: 'ANULADA',
        type: "info",
        icon: "info",
      }, {
        value: 'DECLINED',
        label: 'RECHAZADA',
        type: "danger",
        icon: "circle-close",
      }, {
        value: 'ERROR',
        label: 'ERROR',
        type: "danger",
        icon: "circle-close",
      }],
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      // filter
      consecutive: null,
      status: null,
      nit: null,
    };
  },
  methods: {
    filterTable() {
      this.loadingFilter = true;
      this.getData();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
      console.log(val);
    },
    getData() {
      this.loading = true;
      if (this.consecutive != null || this.status != null || this.nit != null) {
        this.currentPage = 1;
      }
      getAll(
        this.pageSize,
        this.currentPage,
        this.consecutive,
        this.status,
        this.nit,
      )
        .then(({ data }) => {
          this.tableData = data.data.data;
          this.total_rows = data.data.total;
          this.loading = false;
          this.loadingFilter = false;
        })
        .catch(function (error) {
          this.loadingFilter = false;
          this.loading = false;
          console.log(error);
        });
    },
    deleteInvoice(row) {
      this.loading = true;
      deleteData(row.id)
        .then(({ data }) => {
          this.getData();
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    setCategory(categorieJson){
      let categorie = JSON.parse(categorieJson)
      return categorie
    },
    formatN(number){
      return formatNumber(number)
    }
  },
  mounted() {
    if (this.show) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scope>
.mr-10 {
  margin-right: 10px;
}
</style>