<template>
  <div>
    <el-button v-if="!loading" class="" type="success" @click="openCheckoutWompi" :loading="loadingPayment"><i
        class="mdi mdi-shield-check-outline"></i> Paga con <strong>Wompi</strong></el-button>
    <el-button v-else type="primary" icon="el-icon-info" :loading="loading">Esperando comfirmacion del
      pago...</el-button>
  </div>
</template>
<script>
import axios from 'axios';
import { update, updateTransactions } from "@/api/invoice";
export default {
  name: 'ButtonWompi',
  props: ['dataplayer', 'datacheckout', 'loading', 'invoice'],
  watch: {
    loading: function (val) {
      if (val) {
        // console.log("loading", this.loading);
      }
    },
    "invoice": {
      handler: function (nuevoValor, antiguoValor) {
        this.checkTransactionWompi(nuevoValor)
      },
      deep: true // Observa cambios profundos en el objeto
    },
  },
  mounted() {
    this.createWidgetCheckoutWompi()
  },
  data() {
    return {
      loadingPayment: false,
      checkoutWompi: null,
    }
  },
  methods: {
    async createWidgetCheckoutWompi() {
      let me = this
      this.checkoutWompi = await new WidgetCheckout({
        currency: me.datacheckout.currency,
        amountInCents: me.datacheckout.ammount,
        reference: me.datacheckout.reference,
        publicKey: process.env.VUE_APP_WOMPI_PUBLIC_KEY,
        signature: { integrity: me.datacheckout.signatureIntegrity },
        redirectUrl: process.env.VUE_APP_ROOT, // Opcional
        expirationTime: me.datacheckout.expirationTime, // Opcional
        taxInCents: { // Opcional
          vat: me.datacheckout.tax,
          consumption: 0
        },
        customerData: { // Opcional
          email: me.dataplayer.email,
          fullName: me.dataplayer.name_player,
          phoneNumber: me.dataplayer.phone,
          phoneNumberPrefix: '+57',
          legalId: me.dataplayer.nit,
          legalIdType: 'CC'
        },
      })
    },
    openCheckoutWompi() {
      let me = this
      this.checkoutWompi.open(function (result) {
        var transaction = result.transaction
        me.updateTransactionInvoice(transaction)
        console.log('Transaction ID: ', transaction.id)
        console.log('Transaction object: ', transaction)
      })
      setTimeout(() => {
        // Obtener el iframe por la clase 'waybox-iframe'
        var iframe = document.querySelector('.waybox-iframe');

        // Verificar si se encontró el iframe
        if (iframe) {
          // Eliminar la propiedad 'scrolling' del iframe
          iframe.removeAttribute('scrolling');
        }
      }, 500);
    },
    updateTransactionInvoice(transaction) {
      let me = this
      this.loadingPayment = true;
      let data = {
        "environment": me.datacheckout.environment,
        "transaction_id": transaction.id,
        "transaction_status": transaction.status,
        "transaction_payment_method_type": transaction.paymentMethodType
      }
      update(this.dataplayer.invoice.id, data)
        .then(({ data }) => {
          this.loadingPayment = false;
          console.log(data);
          me.verifyTransaction(data.data)
          // setTimeout(function () {
          //   location.reload();
          // }, 2000);
        })
        .catch(function (error) {
          me.loadingPayment = false
          console.log("Err: ", error);
        });
    },
    async checkTransactionWompi(invoice) {
      let me = this
      let invoiceId = invoice.id
      let transactionId = invoice.transaction_id
      await axios.get('https://sandbox.wompi.co/v1/transactions/' + transactionId)
        .then(async response => {
          if (response.data.data.status === "APPROVED") {
            await me.updateTransactionByWompi(invoiceId, response.data.data)
          } else {
            if (response.data.data.status === "PENDING") {
              this.$message({
                type: "info",
                message: "Tu transacción esta en estado PENDIENTE",
              });
              setTimeout(() => {
                me.checkTransactionWompi(invoice)
              }, 10000);
            } else {
              me.$emit('stop-loading', false)
              await me.updateTransactionByWompi(invoiceId, response.data.data)
              this.$message({
                type: "warning",
                message: "Tu transacción no fue aprobada",
              });
            }
          }
        })
        .catch(error => {
          me.$emit('stop-loading', false)
          console.error('Error al consultar la transaccion en Wompi:', error);
        });
    },
    updateTransactionByWompi(invoiceId, transaction) {
      let me = this
      let data = {
        "environment": me.datacheckout.environment,
        "transaction_id": transaction.id,
        "transaction_status": transaction.status,
        "transaction_payment_method_type": transaction.payment_method_type
      }
      updateTransactions(invoiceId, data)
        .then(({ data }) => {
          me.$emit('stop-loading', false)
          console.log(data);
          if (transaction.status === "APPROVED") {
            this.$message({
              type: "success",
              message: "transacción aprobada!!, por favor descarga tus tablas!!",
            });
            me.$emit("show-download", data)
          }
        })
        .catch(function (error) {
          me.$emit('stop-loading', false)
          console.log("Err: ", error);
        });
    },
    verifyTransaction(data) {
      let me = this
      if (data.transaction_status === "APPROVED") {
        this.$message({
          type: "success",
          message: "transacción aprobada!!, por favor descarga tus tablas!!",
        });
        me.$emit('assign-table')
      } else if (data.transaction_status === "PENDING") {
        this.$message({
          type: "info",
          message: "Tu transacción esta en estado PENDIENTE",
        });

      } else {
        this.$message({
          type: "warning",
          message: "Tu transacción no fue aprobada",
        });
      }
    },
  },
}
</script>