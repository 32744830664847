<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>
        <i class="el-icon-collection-tag"></i> Categorías
      </span>
    </div>
    <el-row :gutter="20">
      <el-col :span="9">
        <el-form :model="form" :rules="rules" v-loading="loading" size="medium" ref="form" label-width="120px" class="demo-ruleForm">
          <el-form-item label="Categoría" prop="category">
            <el-input v-model="form.category" placeholder="Ej: A, B, C... etc"></el-input>
          </el-form-item>
          <el-form-item label="Valor por tabla" prop="ammount">
            <el-input-number v-model="form.ammount" controls-position="right" :min="0"
              placeholder="$ 00.00"></el-input-number>
          </el-form-item>
          <el-form-item label="% Subsidio" prop="subsidy">
            <el-input-number v-model="form.subsidy" controls-position="right" :min="0"
              placeholder="$ 00.00"></el-input-number>
          </el-form-item>
          <el-form-item label="% IVA" prop="tax">
            <el-input-number v-model="form.tax" controls-position="right" :min="0"
              placeholder="$ 00.00"></el-input-number>
          </el-form-item>
          <el-form-item class="action-btn">
            <el-button v-if="!edit" type="primary" @click="submitForm('form')" icon="el-icon-check">Guardar</el-button>
            <el-button v-else type="warning" @click="editForm('form')" icon="el-icon-edit">Editar</el-button>
            <el-button @click="resetForm('form')" icon="el-icon-close">Cancelar</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="15">
        <el-table :data="tableData" size="mini" style="width: 100%" v-loading="loadingTable">
          <el-table-column label="Categoría" prop="category" width="110"></el-table-column>
          <el-table-column label="Valor por tabla" prop="ammount">
            <template slot-scope="scope">
              <span>$</span> {{ formatN(scope.row.ammount) }}
            </template>
          </el-table-column>
          <el-table-column label="% Subsidio" prop="subsidy">
            <template slot-scope="scope">
              <span>%</span> {{ scope.row.subsidy }}
            </template>
          </el-table-column>
          <el-table-column label="% IVA" prop="tax">
            <template slot-scope="scope">
              <span>%</span> {{ scope.row.tax }}
            </template>
          </el-table-column>
          <el-table-column label="Acciones" width="110">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="handleEdit(scope.$index, scope.row)"
                circle></el-button>
              <el-button size="mini" icon="el-icon-delete" type="danger" @click="handleDelete(scope.$index, scope.row)"
                circle></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
          style="text-align: end; padding-top: 10px">
          <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
            @current-change="handleCurrentPage"></el-pagination>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import {formatNumber} from '@/utils/number'
import { store, update, deleteData, getAll } from "@/api/invoice/category";
export default {
  name: "InvoiceCategory",
  data() {
    return {
      loadingTable: false,
      loading: false,
      edit: false,
      categoryId: '',
      form: {
        category: '',
        ammount: '',
        subsidy: '',
        tax: '',
      },
      rules: {
        category: [
          { required: true, message: 'Campo obligatorio', trigger: 'blur' },
        ],
        ammount: [
          { required: true, message: 'Campo obligatorio', trigger: 'blur' },
        ],
      },
      tableData: [],
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    }
  },
  mounted() {
    this.loadingTable = false;
    this.getData();
  },
  methods: {
    submitForm(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveForm();
        } else {
          this.loading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.$message({
              type: "success",
              message: "Registro exitoso",
            });
            me.resetForm("form");
            me.getData()
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    handleEdit(indx, row) {
      this.edit = true
      this.categoryId = row.id,
        this.form.category = row.category
      this.form.ammount = row.ammount
      this.form.subsidy = row.subsidy
      this.form.tax = row.tax
    },
    editForm() {
      this.loading = true;
      update(this.categoryId, this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.$message({
              type: "success",
              message: "Registro editado exitosamente",
            });
            this.edit = false
            this.resetForm("form");
            this.getData()
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.edit = false
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
      console.log(val);
    },
    getData() {
      this.loadingTable = true;
      getAll(this.pageSize, this.currentPage)
        .then(({ data }) => {
          var dt = data.data
          this.tableData = dt.data;
          this.total_rows = dt.total;
          this.loadingTable = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log(error);
        });
    },
    handleDelete(idx, row) {
      let me = this;
      me.$swal
        .fire({
          target: "body",
          icon: "warning",
          title: "Estas seguro que desea eliminar este registro?",
          showCancelButton: true,
          confirmButtonText: `Si, eliminar`,
          cancelButtonText: `No, cancelar`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusCancel: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !me.$swal.isLoading(),
          preConfirm: () => {
            me.loadingTable = true;
            return deleteData(row.id)
              .then(({ data }) => {
                me.loadingTable = false;
                me.getData()
              })
              .catch(function (error) {
                me.loadingTable = false;
                console.log(error);
              });
          },
        })
        .then((result) => {
          if (result.value) {
            me.$message({
              type: "success",
              message: "El registro ha sido eliminado exitosamente",
            });
          } else {
            me.getSerial();
          }
        });
    },
    formatN(number){
      return formatNumber(number)
    }
  },
}
</script>

<style lang="scss">
.action-btn {
  .el-form-item__content {
    margin-left: 0px !important;
  }
}

.el-input-number {
  width: 100%;
}
</style>