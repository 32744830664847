<template>
  <div class="form-wrapper align-vertical-middle" v-loading="loading_table" :element-loading-text="text_loading"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <img class="image-logo" :src="pathImagesUrl + '/banner.png'" alt="logo" />
    <div class="no-play" v-if="verify_data && !loading_verify">
      Bingo!!
      <br />
      <div class="return-play exclude">
        Descarga ya tu(s) tabla(s) y no te quedes sin Jugar!
      </div>
      <div class="content-Invoice">
        <el-result icon="success" title="Transacción aprobada">
          <template slot="extra">
            <div class="invoice-info">
              Metodo de pago:
              <span>{{ invoiceData.transaction_payment_method_type }}</span>
            </div>
            <div class="invoice-info">
              Referencia: <span>{{ invoiceData.transaction_reference }}</span>
            </div>
            <div class="invoice-info">
              Fecha de transaccion:
              <span>{{ formatDate(invoiceData.updated_at) }}</span>
            </div>
            <table class="invoice-table">
              <tr>
                <td class="info">Valor tabla</td>
                <td class="value">$ {{ formatN(category.ammount) }}</td>
              </tr>
              <tr>
                <td class="info">IVA</td>
                <td class="value">$ {{ formatN(category.value_tax) }}</td>
              </tr>
              <tr>
                <td class="info">Subsidio</td>
                <td class="value">$ {{ formatN(category.value_subsidy) }}</td>
              </tr>
              <tr>
                <th class="total">Total a pagar</th>
                <th class="total-value">$ {{ formatN(category.ammount - category.value_subsidy) }}</th>
              </tr>
            </table>
          </template>
        </el-result>
      </div>
      <el-button class="btn-download-table exclude" type="primary" icon="el-icon-download" @click="downloadTable"
        :loading="loading_download">Descargar Tabla!</el-button>
      <el-button class="btn-print exclude" icon="el-icon-printer" @click="printInvoice">Imprimir recibo</el-button>
    </div>
    <div v-if="loading_verify" class="loading-game">
      <i class="el-icon-loading"></i> Validando registro...
    </div>
    <button-return-register class="exclude"></button-return-register>
  </div>
</template>
<script>
import ButtonReturnRegister from "@/views/apps/modules/ButtonReturnRegister.vue";
import { downloadTable } from "@/api/player";
import { formatNumber } from "@/utils/number";
export default {
  name: "DownloadTable",
  props: ["tables", "invoice"],
  components: {
    ButtonReturnRegister,
  },
  data() {
    return {
      pathImagesUrl: process.env.VUE_APP_ROOT + 'images/' + process.env.VUE_APP_FOLDER_IMAGES,
      text_loading: "",
      loading_download: false,
      loading_table: false,
      loading_verify: false,
      verify_data: true,
      msn: "",
      category: this.invoice.category,
      invoiceData: this.invoice.invoice
    };
  },
  beforeMount() {
    // verificacion del email
  },
  methods: {
    downloadTable() {
      this.loading_download = true;
      if (this.tables !== null) {
        downloadTable(this.tables)
          .then(({ data }) => {
            this.downloadFile(data, "Mis tablas de bingo");
          })
          .catch(function (error) {
            this.loading_download = false;
            this.$message.error("Error.");
            console.log(error);
          });
      } else {
        this.$message({
          type: "warning",
          message: "No se generaron tablas!",
        });
        this.loading_download = false;
      }
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      this.loading_download = false;
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    formatN(number) {
      return formatNumber(number)
    },
    formatDate(dateString) {
      const fechaString = dateString;
      const fecha = new Date(fechaString);

      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
      const formatoFecha = fecha.toLocaleDateString('es-ES', options);
      const formatoHora = fecha.toLocaleTimeString('es-ES', options);
      return formatoFecha.slice(0, -5);
    },
    printInvoice() {
      window.print();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/_variables";

.content-Invoice {
  text-align: -webkit-center;
  background-color: white;
  position: relative;
  padding: 15px;
  border-radius: 20px;

  .invoice-info {
    font-size: 14px;
    color: #676767;
    font-weight: 600;
    text-align: left;
    padding: 2px;

    span {
      font-weight: bold;
    }
  }

  .invoice-table {
    margin-top: 20px;
    font-size: 15px;
    color: #676767;
    font-weight: 600;

    .info,
    .total {
      text-align: left;
      width: 200px;
    }

    .value,
    .total-value {
      text-align: right;
    }

    .total,
    .total-value {
      border-top: 1px solid;
      font-size: 19px;
    }
  }
}

.register-page {

  .return-play {
    font-size: 25px;
    color: #0868AE;
    font-weight: 900;
  }

  .no-play {
    text-align: center;
    color: #da5635;
    border: 2px solid #DBDBEA;
    border-radius: 30px;
    font-size: 40px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 40px;
    margin-top: 50px;
    width: 450px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }

  .loading-game {
    color: #0868AE;
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
  }

  // .return-play {
  //   font-size: 25px;
  //   color: #ffee78;
  //   font-weight: 900;
  // }
  // .return-play:hover {
  //   color: #ffdf00;
  // }


  .accept {
    margin-top: 20px;
  }

  .form-wrapper {
    width: 100%;
  }

  .image-logo {
    width: 300px;
    margin: 0px auto;
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.register-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }

  .register-page {
    .image-logo {
      margin-top: 0px;
    }
  }
}

@media (max-width: 550px) {
  .register-page {
    .no-play {
      width: auto;
    }
  }
}

@media (max-width: 390px) {
  .register-page {
    .no-play {
      font-size: 22px;

      .return-play {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 330px) {
  .register-page {
    .image-logo {
      width: 200px;
    }

    .btn-download-table {
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
@media print {
  .exclude {
    display: none;
  }

  @page {
    margin-bottom: 0;
  }

  body {
    margin-bottom: 0;
  }
}
</style>
